import { defineComponent, defineAsyncComponent, toRefs, reactive } from "vue";
import Eldialog from "@/components/Eldialog/index.vue";
import API from "@/api/education/index";
import AreaSelect from "@/components/AreaSelect/index.vue";
import { useMessage } from "@/hooks/web/useMessage";
import Emitter from "@/eventBus/index";
import { useDebounce } from '@/hooks/core/useDebounce';
import authorityAPI from "@/api/authority/index";
import TypeSelect from "@/components/TypeSelect/TypeSelect.vue";
export default defineComponent({
  name: "School",
  components: {
    Eldialog,
    AreaSelect,
    TypeSelect,
    OrganizeSelect: defineAsyncComponent(() => import("@/components/OrganizeSelect/index.vue")),
    Resource: defineAsyncComponent(() => import("../../authority/components/Resource.vue"))
  },
  props: {
    info: {
      type: Object,
      default: null
    },
    orgInfo: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:visible", "handleSuccess"],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      resourceRef: null,
      resourceVisible: false,
      roleOrgId: 1,
      value: "",
      searchValue: '',
      activeValue: null,
      selectAreaValue: [],
      ruleForm: null,
      formData: null,
      title: '新增单位',
      roleType: [],
      rules: {
        orgId: [{
          required: true,
          message: '请选择上级组织'
        }],
        num: [{
          required: false,
          message: '请填写部门数量'
        }],
        schoolName: [{
          required: true,
          message: "请输入所属单位",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '所属单位不能带有特殊符号'
        }],
        loginName: [{
          required: true,
          message: "请输入登录账号",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '登录账号不能带有特殊符号'
        }],
        schoolType: [{
          required: true,
          message: "请选择单位类型",
          type: 'number',
          trigger: 'change'
        }],
        roleId: [{
          required: true,
          message: "请选择登录权限",
          type: 'number',
          trigger: 'change'
        }],
        educationStage: [{
          required: true,
          message: "请选择阶段",
          type: 'number',
          trigger: 'change'
        }],
        headName: [{
          required: false,
          message: "请输入负责人姓名",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '负责人姓名不能带有特殊符号'
        }],
        headPhone: [{
          required: false,
          message: "请输入负责人电话",
          type: 'string',
          trigger: 'blur'
        }, {
          pattern: /^1[3456789]\d{9}$/,
          message: '请填写正确手机号码'
        }],
        schoolAddress: [{
          required: true,
          message: "请输入单位地址",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 60,
          message: '长度在 1 到 40 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '单位地址不能带有特殊符号'
        }],
        provinces: [{
          required: true,
          message: "请选择所属行政区域",
          type: 'string',
          trigger: 'change'
        }]
      }
    });
    //获取当前账号下的全部角色
    const getRole = async () => {
      const {
        data,
        code,
        msg
      } = await authorityAPI.listRole({
        deptId: refData.formData.orgId ? refData.formData.orgId : 1
      });
      if (code === 0) {
        refData.roleType = data.list.map(item => ({
          value: item.id,
          label: item.name,
          ...item
        }));
      }
    };
    //赋值组织
    const handleAnyway = () => {
      refData.formData.orgId = props.orgInfo.id;
      refData.searchValue = props.orgInfo.name;
      refData.activeValue = props.orgInfo.activeValue;
      refData.ruleForm.clearValidate();
      getRole();
    };
    //保存按钮
    const confirm = () => {
      refData.ruleForm.validate(async valid => {
        if (valid) {
          try {
            let refMsg = '';
            let refFlag = 0;
            if (props.info === null) {
              const {
                msg,
                code
              } = await API.schoolAdd(refData.formData);
              refMsg = msg;
              refFlag = code;
            } else {
              const {
                msg,
                code
              } = await API.schoolUpdate({
                id: props.info.schoolId,
                ...refData.formData
              });
              refMsg = msg;
              refFlag = code;
            }
            if (refFlag !== 0) throw refMsg;
            message.success('成功');
            emit("update:visible", false);
            emit("handleSuccess");
            Emitter.emit('updateOrg');
            Emitter.emit('handleShow');
          } catch (error) {
            message.error(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    };
    // 参数复制
    const getData = () => {
      Object.keys(props.info).forEach(key => {
        if (refData.formData[key] !== undefined) {
          refData.formData[key] = props.info[key];
        }
      });
      refData.searchValue = props.info.orgName;
      refData.selectAreaValue = [props.info.provinces, props.info.city, props.info.county];
    };
    // 赋值地区
    const handleSelectArea = item => {
      refData.formData.provinces = item[0];
      refData.formData.city = item[1];
      refData.formData.county = item[2];
    };
    //初始化数据
    const init = () => {
      refData.formData = {
        num: 10,
        schoolName: null,
        schoolType: null,
        headName: null,
        orgId: null,
        headPhone: null,
        provinces: null,
        schoolAddress: null,
        city: null,
        county: null,
        educationStage: 7,
        remark: '',
        roleId: 3,
        loginName: ''
      };
      refData.selectAreaValue = [];
      refData.searchValue = '';
    };
    // 点击组织树
    const handleDoubleClick = data => {
      refData.formData.orgId = data.id;
      getRole();
    };
    //新增角色
    const handleAddRole = () => {
      if (!refData.formData.orgId) return message.warning('请先选择上级组织');
      refData.roleOrgId = refData.formData.orgId;
      refData.resourceVisible = true;
      refData.resourceRef.isAdd = true;
    };
    // 查看权限
    const visibleChange = roleType => {
      if (!refData.formData.orgId) return message.warning('请先选择上级组织');
      refData.resourceVisible = true;
      refData.roleOrgId = refData.formData.orgId;
      refData.resourceRef.type = refData.roleType.filter(item => roleType == item.value);
      refData.resourceRef.isAdd = false;
    };
    //防抖
    const [useDebounceConfirm] = useDebounce(confirm, 400);
    init();
    return {
      ...toRefs(refData),
      useDebounceConfirm,
      visibleChange,
      handleAnyway,
      confirm,
      getData,
      handleSelectArea,
      init,
      handleDoubleClick,
      getRole,
      handleAddRole
    };
  }
});